import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 导入demo
import "./assets/css/demo.css";
// 导入axios
import axios from "axios";
import Base64 from "./utils/base64";
// import jQuery from "./views/pflip/js/libs/jquery.min.js";

// 配置axios的基地址
// axios.defaults.baseURL = "http://112.26.202.82:9600/fts/v1/";
// axios.defaults.baseURL = "http://112.26.202.82:9600/fts/v1/"; // 9600是移动端的服务
// axios.defaults.baseURL = "http://112.26.202.82:9500/fts/v1/"; // 9500是管理后台服务

const instance1 = axios.create({
  // baseURL: "http://112.26.202.82:9600/fts/v1/",
  baseURL: "https://app.zqyp.net/fts/v1",
  // baseURL: "http://192.168.2.164:9600/fts/v1/",

});

const instance2 = axios.create({
  // baseURL: "http://112.26.202.82:9500/fts/v1/",
  baseURL: "https://client.zqyp.net/fts/v1/",
});
// 将jQuery绑定到Vue原型中，使其在全局范围内可用

Vue.prototype.$jQuery = jQuery;

Vue.prototype.$Base64 = Base64;

Vue.prototype.instance1 = instance1;
Vue.prototype.instance2 = instance2;
Vue.prototype.$http = axios;
Vue.prototype.$EventBus = new Vue();

Vue.use(ElementUI);

Vue.mixin({
  methods: {
    $decodeBase64(encodedString) {
      return Base64.decode(encodedString);
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
