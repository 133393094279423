<template>
  <div>
    <div class="top">
      <div class="logo">
        <img src="@/assets/png/home/zqw.png" alt="" />
      </div>
      <div class="tab">
        <div class="box" @click="toHome()">首页</div>
        <div class="box" @click="toHundred()">百家姓</div>
        <div class="box" @click="toAncestors()">寻亲问祖</div>
        <div class="box" @click="toGenealogy()">家谱库</div>
        <div class="box" @click="toPartyBuilding()">党建学习</div>
        <div class="box acitves" @click="toDownload()">下载中心</div>
        <div class="box" @click="toAbout()">关于我们</div>
      </div>
    </div>
    <div class="banner">
      <img src="@/assets/png/home/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="content-con">
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="con">
        <div class="surname">
          <div class="download">
            <div class="box1">
              <div class="top2">
                <div class="left1">
                  <img src="@/assets/png/home/windows.png" alt="EXE Image" />
                </div>
                <div class="right1">
                  <p style="font-size: 23px">众亲云谱客户端</p>
                  <p style="font-size: 15px">{{ clientVersion }} 版本</p>
                </div>
              </div>
              <div class="con1 images">
                <!-- 111 -->
                <img
                  src="@/assets/png/home/download.png"
                  alt=""
                  class=""
                  @click="downloadExe()"
                />
              </div>
            </div>
            <div
              class="qRCode"
              :style="qRCodeShow === false ? coverImgUrl : ''"
              @mouseover="qRCodeShow = true"
              @mouseout="qRCodeShow = false"
            >
              <div class="top2" v-show="qRCodeShow === false">
                <div class="left1">
                  <img src="@/assets/png/home/Android.png" alt="" />
                </div>
                <div class="right1">
                  <p style="font-size: 23px">众亲APP</p>
                  <p style="font-size: 15px">{{ androidVersion }} 版本</p>
                </div>
              </div>
              <div class="con1" v-show="qRCodeShow === false">
                <img src="@/assets/png/home/scanCode.png" alt="" />
              </div>

              <!-- 二维码图片 -->
              <div v-show="qRCodeShow === true">
                <div
                  id="qrCode"
                  ref="qrCodeDiv"
                  style="margin-left: 20px"
                ></div>
              </div>
            </div>

            <div class="box1">
              <div class="top2">
                <div class="left1">
                  <img src="@/assets/png/home/ios.png" alt="" />
                </div>
                <div class="right1">
                  <p style="font-size: 23px">众亲APP</p>
                  <!-- <p style="font-size: 15px">苹果IOS {{ iosList }}以上版本</p> -->
                  <p style="font-size: 15px">
                    请到苹果应用商店搜索《众亲》获取
                  </p>
                </div>
              </div>
              <div class="con1">
                <img src="@/assets/png/home/AppStore.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="e">
        <div class="left2">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="right2">
          <p>版权所有:&nbsp;&nbsp;安徽图腾传承文化有限公司</p>
          <div style="display: flex">
            <p>客服电话:&nbsp;&nbsp;18963782919</p>
            <p style="margin-left: 20px">
              企业邮箱:&nbsp;&nbsp;zhzqwvip@163.com
            </p>
          </div>
          <div style="display: flex">
            <p>备案号:&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备1833721395-1号</a></p>
            <p style="margin-left: 20px">
              公安备案:&nbsp;&nbsp;皖公网安备 34019202000232号
            </p>
            <!-- <p style="margin-left: 20px">编委会管理中心</p> -->
            <a
              href="https://client.zqyp.net/"
              target="_blank"
              style="margin-left: 20px"
              >编委会管理中心</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  data() {
    return {
      url: "",

      clientVersion: "",

      androidVersion: "",

      qRCodeShow: false,

      coverImgUrl: {
        backgroundImage:
          "url(" + require("@/assets/png/home/roundedRectangle.png") + ")",
      },

      isActive: "1",
      // 寻亲问祖
      menusList: [],
      // 百家姓
      slicedArrayList: [],
      // 定义字体对象
      iconsObj: {
        125: "iconfont icon-user",
        103: "iconfont icon-tijikongjian",
        101: "iconfont icon-shangpin",
        102: "iconfont icon-danju",
        145: "iconfont icon-baobiao",
      },
      //激活的菜单项目
      activePath: "",
      // 控制左侧菜单列表的折叠布尔值
      isCollapse: false,
      genealogyList: [],

      childList: [],

      iosList: [],
    };
  },

  created() {
    // 家谱库
    this.getGenealogy();
    // IOS版本
    this.getIOS();

    // 获取安卓二维码
    this.getAndroidCode();
    this.instance1.get("port/access/save?portTypeCode=PC_LMFW_XZZX");
  },
  methods: {
    downloadExe() {
      console.log("🚀 ~ downloadExe ~ this.url:", this.url);
      window.open(this.url);
    },

    // 二维码数据
    bindQRCode: function (url) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: url,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },

    async getAndroidCode() {
      // const { data: res } = await this.instance1.post("appVersion/getList");
      const { data: res } = await this.instance2.post("appVersion/getList");
      console.log("🚀 ~ getAndroidCode ~ res:", res);
      res.data.forEach((element) => {
        if (element.type === 1 && element.url !== null) {
          // this.qRCodeShow=false
          this.bindQRCode(element.url);

          this.androidVersion = element.version;
        } else if (element.type === 3 && element.url !== null) {
          this.url = element.url;
          this.clientVersion = element.version;
        }
      });
    },

    // IOS版本
    async getIOS() {
      const { data: res } = await this.instance1.get(
        `management/checkVersion/${2}`
      );
      this.iosList = res.data;
    },

    genealogyClick(item) {
      this.childList = item.childList;
      this.isActive = item.id;
    },

    // 家谱库
    async getGenealogy() {
      const { data: res } = await this.instance1.get(
        `management/checkVersion/${1}`
      );

      console.log(res, "res");
      this.genealogyList = res.data;

      // res.data.forEach((item, index) => {
      //   if (this.isActive == item.id) {
      //     this.childList = item.childList;
      //   }
      // });
    },

    toHome() {
      this.$router.push({
        path: "/",
      });
    },
    toHundred() {
      this.$router.push({
        path: "./surnamelist",
      });
    },
    toAncestors() {
      this.$router.push({
        path: "./ancestors",
      });
    },
    toGenealogy() {
      this.$router.push({
        path: "./genealogy",
      });
    },

    // 党建学习
    toPartyBuilding() {
      this.$router.push({
        path: "./partyBuilding",
      });
    },

    toDownload() {
      // this.$router.push({
      //   path: "./download",
      // });
    },
    toAbout() {
      this.$router.push({
        path: "./about",
      });
    },
  },
};
</script>

<style scoped>
.surname {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
}

.download {
  width: 100%;
  height: 246px;
  display: flex;
  justify-content: space-around;
}

.box1 {
  width: 31%;
  height: 226px;
  background-image: url(@/assets/png/home/roundedRectangle.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.qRCode {
  width: 31%;
  height: 226px;
  background-color: #fff;
  border-radius: 28px;
  /* background-image: url(@/assets/png/home/roundedRectangle.png); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  z-index: 99999 !important;
}

.images {
  cursor: pointer;
  z-index: 99999 !important;
}

.top2 {
  width: 80%;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

.left1 {
  width: 60px;
  height: 60px;
}

.right1 {
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
}

.con1 {
  width: 55px;
  height: 55px;
}

.con1 img {
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f8f8f8;
}

.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top .logo {
  width: 170px;
  height: 73px;
}

.logo > img {
  width: 100%;
  height: 100%;
}

.top .tab {
  width: 38%;
  display: flex;
  justify-content: space-between;
}

.top .tab .box {
  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  height: 35px;
}

.acitves {
  border-bottom: 2px solid #cd4f4b;
}

.banner {
  width: 100%;
  height: 400px;
}

.banner img {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
}

.content .content-con {
  position: absolute;
  left: 16%;
  width: 67%;
  height: calc((1996px - 645px - 161px) / 2);
  display: flex;
}

.content .content-con .left {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/left.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  margin-bottom: 230px;
  z-index: 22;
}

.content .content-con .right {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/right.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 280px;
  z-index: 22;
}

.con {
  width: 67%;
  height: 400px;

  margin: 0 auto;

  display: flex;
  flex-direction: column;
}

.top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
  position: relative;
}

.xian2 {
  width: 88%;
  height: 1px;
  background-color: #dddddd;
}

.more {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: #c2c2c2;
}

.e {
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}

.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.foot {
  width: 100%;
  height: 161px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
}
</style>
