<template>
  <div>
    <div class="top">
      <div class="logo">
        <img src="@/assets/png/home/zqw.png" alt="" />
      </div>
      <div class="tab">
        <div class="box" @click="toHome()">首页</div>
        <div class="box" @click="toHundred()">百家姓</div>
        <div class="box" @click="toAncestors()">寻亲问祖</div>
        <div class="box" @click="toGenealogy()">家谱库</div>
        <div class="box" @click="toPartyBuilding()">党建学习</div>

        <div class="box" @click="toDownload()">下载中心</div>
        <div class="box acitves" @click="toAbout()">关于我们</div>
      </div>
    </div>
    <div class="banner">
      <img src="@/assets/png/home/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="content-con">
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="con">
        <div class="surname">
          <div class="top2">关于我们</div>
          <div
            style="
              width: 100%;
              height: calc(100% - 52px - 131px);
              padding: 50px 67px 131px 40px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
            "
          >
            <div class="con1">
              <p>
                众亲网是中国第一家以“姓氏”为纽带，以“族谱”为表现方式的专业性门户网站，致力于打造中华家谱网络版，搭建“全球宗亲一线牵”的全网平台。
              </p>
            </div>
            <div class="con1">
              <p>
                众亲网坚持以用户体验为中心，一级页面设有百家姓、新闻报等栏目，实现网站一般功能和游客体验功能;同时设立二级姓氏页面，以姓氏文
                化为元素，对用户自身姓氏进行追本溯源和详细介绍，挖掘姓氏的内涵及代表性人和事，促成全球范围内的“寻根问祖”，构建“姓氏朋友圈”；三级家族页面中用户
                可以通过账号登录实现家族族谱查询、信息填报、家族互动、生日祝福、信息发布、心灵日记等功能，既能够通过网络空间建立家族族谱的电子版，又能通
                过后台大数据实现本家族与其他家族的互动联系。
              </p>
            </div>
            <div class="con1">
              <p>
                众亲网自运营以来，得到了广大网友的积极参与，收获了众多好评和建议，本网站承诺将始终践行“弘扬中华文化传承家风美德”的宗旨，站在用户角度，以不断提升的网络信息化手段搭建中华儿女的网上心灵家园。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="e">
        <div class="left2">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="right2">
          <p>版权所有:&nbsp;&nbsp;安徽图腾传承文化有限公司</p>
          <div style="display: flex">
            <p>客服电话:&nbsp;&nbsp;18963782919</p>
            <p style="margin-left: 20px">
              企业邮箱:&nbsp;&nbsp;zhzqwvip@163.com
            </p>
          </div>
          <div style="display: flex">
            <p>备案号:&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备1833721395-1号</a></p>
            <p style="margin-left: 20px">
              公安备案:&nbsp;&nbsp;皖公网安备 34019202000232号
            </p>
            <!-- <p style="margin-left: 20px">编委会管理中心</p> -->
            <a
              href="https://client.zqyp.net/"
              target="_blank"
              style="margin-left: 20px"
              >编委会管理中心</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 寻亲问祖
      menusList: [],
      // 百家姓
      slicedArrayList: [],
      // 定义字体对象
      iconsObj: {
        125: "iconfont icon-user",
        103: "iconfont icon-tijikongjian",
        101: "iconfont icon-shangpin",
        102: "iconfont icon-danju",
        145: "iconfont icon-baobiao",
      },
      //激活的菜单项目
      activePath: "",
      // 控制左侧菜单列表的折叠布尔值
      isCollapse: false,
    };
  },
  created() {
    // // 百家姓
    // this.getSlicedArray();
    // // 寻亲问祖
    // this.getMenus();
    // this.activePath = localStorage.getItem('path')
    this.instance1.get("port/access/save?portTypeCode=PC_LMFW_GYWM");

  },
  methods: {
    // 首页
    toHome() {
      this.$router.push({
        path: "/",
      });
    },
    // 百家姓
    toHundred() {
      this.$router.push({
        path: "./surnameList",
      });
    },
    // 寻亲问祖
    toAncestors() {
      this.$router.push({
        path: "./ancestors",
      });
    },
    // 家谱库
    toGenealogy() {
      this.$router.push({
        path: "./genealogy",
      });
      // location.href = "./genealogy.html";
    },

    // 党建学习
    toPartyBuilding() {
      this.$router.push({
        path: "./partyBuilding",
      });
    },

    // 下载中心
    toDownload() {
      this.$router.push({
        path: "./download",
      });
      // location.href = "./download.html";
    },
    // 关于我们
    toAbout() {
      // this.$router.push({
      //   path: "./about",
      // });
      // location.href = "./about.html";
    },
  },
};
</script>

<style scoped>
.surname {
  width: 100%;
  height: 500px;
  background-color: #fff;
}

.top2 {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #bf1c29;
  font-size: 20px;
  padding-left: 27px;
  color: #333333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.con1 {
  line-height: 40px;
  font-size: 17px;
  color: #333333;
  text-indent: 2em;
}

* {
  margin: 0;
  padding: 0;
}

.active {
  border-bottom: 2px solid #bf1c29;
}

body {
  background-color: #f8f8f8;
}

.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top .logo {
  width: 170px;
  height: 73px;
}

.logo > img {
  width: 100%;
  height: 100%;
}

.top .tab {
  width: 38%;
  display: flex;
  justify-content: space-between;
}

.top .tab .box {
  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  height: 35px;
}

.acitves {
  border-bottom: 2px solid #cd4f4b;
}

.banner {
  width: 100%;
  height: 400px;
}

.banner img {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: calc((1996px - 645px - 161px) / 2);
  position: relative;
  overflow: hidden;
}

.content .content-con {
  position: absolute;
  left: 16%;
  width: 67%;
  height: calc((1996px - 645px - 161px) / 2);
  display: flex;
}

.content .content-con .left {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/left.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  margin-bottom: 230px;
  z-index: 22;
}

.content .content-con .right {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/right.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 280px;
  z-index: 22;
}

.con {
  width: 67%;
  height: calc((1996px - 645px - 161px) / 2);

  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
  position: relative;
}

.xian2 {
  width: 88%;
  height: 1px;
  background-color: #dddddd;
}

.more {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: #c2c2c2;
}

.e {
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}

.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.foot {
  width: 100%;
  height: 161px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
}
</style>
