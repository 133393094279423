<template>
  <div>
    <div class="top">
      <div class="logo">
        <img src="@/assets/png/home/zqw.png" alt="" />
      </div>
      <div class="tab">
        <div class="box" @click="toHome">首页</div>
        <div class="box acitves" @click="toHundred">百家姓</div>
        <div class="box" @click="toAncestors">寻亲问祖</div>
        <div class="box" @click="toGenealogy">家谱库</div>
        <div class="box" @click="toPartyBuilding">党建学习</div>
        <div class="box" @click="toDownload">下载中心</div>
        <div class="box" @click="toAbout">关于我们</div>
      </div>
    </div>
    <div class="banner">
      <img src="@/assets/png/home/banner.png" alt="" class="aaa" />
    </div>
    <div class="content">
      <div class="content-con">
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="con" style="">
        <div class="mune">
          <div class="mune-top" style="margin-bottom: 20px">百家姓</div>
          <div
            class="mune-item"
            v-for="(item, index) in letterList"
            :key="index"
          >
            <p :class="[item == isActive ? 'active' : '']" @click="letterClcik">
              {{ item }}
            </p>
          </div>
        </div>
        <div class="surname">
          <div class="top2">百家姓</div>
          <div class="surname-content">
            <div
              v-for="item in slicedArrayList"
              :key="item.id"
              :style="{
                fontSize:
                  item.lastName.length == 1
                    ? '24px'
                    : item.lastName.length == 2
                    ? '20px'
                    : item.lastName.length == 3
                    ? '16px'
                    : item.lastName.length == 4
                    ? '12px'
                    : '',
                backgroundColor: item.haveFamily == true ? '#FFFFE0' : '',
              }"
              @click="surnamesClick(item)"
            >
              {{ item.lastName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="e">
        <div class="left2">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="right2">
          <p>版权所有:&nbsp;&nbsp;安徽图腾传承文化有限公司</p>
          <div style="display: flex">
            <p>客服电话:&nbsp;&nbsp;18963782919</p>
            <p style="margin-left: 20px">
              企业邮箱:&nbsp;&nbsp;zhzqwvip@163.com
            </p>
          </div>
          <div style="display: flex">
            <p>备案号:&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备1833721395-1号</a></p>
            <p style="margin-left: 20px">
              公安备案:&nbsp;&nbsp;皖公网安备 34019202000232号
            </p>
            <!-- <p style="margin-left: 20px">编委会管理中心</p> -->
            <a
              href="https://client.zqyp.net/"
              target="_blank"
              style="margin-left: 20px"
              >编委会管理中心</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "surnamelist",
  data() {
    return {
      letterList: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "L",
        "J",
        "K",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      isActive: "A",
      // 百家姓
      slicedArrayList: [],

      resData: [],
    };
  },
  created() {
    // 百家姓
    this.getSlicedArray();
    //     // 寻亲问祖
    //     this.getMenus();
    // this.activePath = localStorage.getItem('path')
  },
  methods: {
    surnamesClick(item) {
      console.log(item, "item");
      this.$router.push({
        path: "./surnameDetails",
        query: {
          lastName: item.lastName,
        },
      });
    },
    // 点击英文字母
    letterClcik(e) {
      // console.log(e);
      this.isActive = e.target.innerText;
      let bbb = this.resData.filter((it, idx) => {
        return it.firstLetter == e.target.innerText;
      });
      this.slicedArrayList = bbb;
    },

    // 百家姓

    async getSlicedArray() {
      const { data: res } = await this.instance1.get("familyNames/findList?portTypeCode=PC_LMFW_BJX");
      this.resData = res.data;
      let bbb = res.data.filter((it, idx) => {
        // console.log(item.firstLetter, 'item.firstLetter');
        // console.log(e.target.innerText, 'e.target.innerText');
        return it.firstLetter == "A";
      });
      this.slicedArrayList = bbb;
    },
    // 首页
    toHome() {
      this.$router.push({
        path: "/",
      });
    },
    // 百家姓
    toHundred() {
      // this.$router.push({
      //   path: "./surnameList",
      // });
    },
    // 寻亲问祖
    toAncestors() {
      this.$router.push({
        path: "./ancestors",
      });
    },
    // 家谱库
    toGenealogy() {
      this.$router.push({
        path: "./genealogy",
      });
      // location.href = "./genealogy.html";
    },

    // 党建学习
    toPartyBuilding() {
      this.$router.push({
        path: "./partyBuilding",
      });
    },

    // 下载中心
    toDownload() {
      this.$router.push({
        path: "./download",
      });
      // location.href = "./download.html";
    },
    // 关于我们
    toAbout() {
      this.$router.push({
        path: "./about",
      });
      // this.$router.push({
      //   path: "./about",
      // });
      // location.href = "./about.html";
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f8f8f8;
}

.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top .logo {
  width: 170px;
  height: 73px;
}

.logo > img {
  width: 100%;
  height: 100%;
}

.top .tab {
  width: 38%;
  display: flex;
  justify-content: space-between;
}

.top .tab .box {
  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  height: 35px;
}

.acitves {
  border-bottom: 2px solid #cd4f4b;
}

.banner {
  width: 100%;
  height: 400px;
}

.banner img {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: calc(1996px - 645px - 161px);
  position: relative;
  /* z-index: 1; */
}

.content .content-con {
  position: absolute;
  /* z-index: 1111111; */
  left: 16%;
  width: 67%;
  height: calc(1996px - 645px - 161px);
  display: flex;
}

.content .content-con .left {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/left.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  margin-bottom: 230px;
  /* z-index: 22; */
}

.content .content-con .right {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/right.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 280px;
  /* z-index: 22; */
}

.con {
  width: 67%;
  height: calc(1996px - 645px - 161px);

  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
  position: relative;
  /* z-index: 1; */
}

.xian2 {
  width: 88%;
  height: 1px;
  background-color: #dddddd;
}

.more {
  position: absolute;
  /* z-index: 1111111; */
  right: 0;
  top: 0;
  font-size: 12px;
  color: #c2c2c2;
}

.e {
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}

.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.foot {
  width: 100%;
  height: 161px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
}

.surname {
  width: 80%;
  height: 1000px;
  z-index: 999999999 !important;
  /* background-color: #fff; */
}

.top2 {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #bf1c29;
  font-size: 20px;
  padding-left: 27px;
  color: #333333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.con1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.bottom {
  width: 100%;
  height: 131px;

  display: flex;
  align-items: center;
  padding-left: 40px;
}

.box2 {
  padding: 10px;
  border: 1px solid #c2c2c2;
  margin-bottom: 20px;
}

.mune {
  width: 15%;
  height: 1000px;
  background-color: #fff;
}

.mune-top {
  width: 100%;
  height: 50px;
  background: linear-gradient(to right, rgb(194, 37, 45), rgb(253, 195, 0));
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 50px;
}

.mune-item {
  width: 100%;
  height: 35px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.mune-item p {
  width: 25px;
  font-size: 15px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  z-index: 999;
}

.active {
  color: #fff;
  background-color: rgb(194, 37, 45);
}

.surname-content div {
  cursor: pointer !important;
  /* z-index: 999999999 !important; */
  /* padding: 0 20px; */
  width: 70px;
  height: 70px;
  background: url(@/assets/png/home/grid.png) no-repeat;
  background-size: 100% 100%;
  /* font-size: 24px; */
  /* font-size: 24px; */
  text-align: center;
  line-height: 70px;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}

.surname-content {
  width: 100%;
  height: calc(100% - 52px);

  padding: 35px;
  box-sizing: border-box;
}
</style>
